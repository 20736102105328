@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
// Override default variables before the import
// $body-bg: #000;

@import "./fonts.scss";
@import "./nav.scss";

// Import Bootstrap and its default variables
@import "~bootstrap/scss/bootstrap.scss";
